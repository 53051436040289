import axios from 'axios'
import store from "@/store";
import router from "@/router";

const axiosIns = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL,
});

axiosIns._refresh = false;
axiosIns._refreshError = false;

axiosIns.interceptors.request.use((config) => {
  if (!axiosIns._refresh && (store.getters['auth/getExpiresInTime'] <= (new Date()).getTime())) {
    store.dispatch('auth/refresh');
  }

  return new Promise((resolve) => {
    setTimeout(function run() {
      if (axiosIns._refreshError) return;
      if (!axiosIns._refresh || config._refresh) {
        resolve({
          ...config,
          headers: {
            Authorization: store.getters['auth/getAccessTokenWithType'],
          },
        });
      } else {
        setTimeout(run);
      }
    });
  })
});

axiosIns.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status !== 401) {
      switch (error.response.status) {
        case 403:
          router.push({name: 'access_denied'});
          break;
      }

      return new Promise((resolve, reject) => {
        reject(error);
      });
    }

    store.dispatch('auth/logout');
    router.push({name: 'login'});
    axiosIns._refreshError = true;

    return new Promise((resolve, reject) => {
      reject(error);
    });
  });

export default axiosIns;
