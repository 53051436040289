const alertLife = process.env.VUE_APP_ALERT_LIFE;

export default {
  success: (message) => {
    return { severity: 'success', summary: 'Successful', detail: message, life: alertLife };
  },

  error: (message) => {
    return { severity: 'error', summary: 'Error', detail: message, life: alertLife };
  },
}
