import axios from "@/libs/axios";

const endpointPrefix = process.env.VUE_APP_API_PREFIX;

export default {
  newTools(params) {
    return axios.get(`/${endpointPrefix}/tools/new-tools`, { params });
  },
  pricesAndTools(params) {
    return axios.get(`/${endpointPrefix}/tools/prices-and-tools`, { params });
  },
  all(params) {
    return axios.get(`/${endpointPrefix}/tools/all`, { params });
  },
  visibleAndPublished() {
    return axios.get(`/${endpointPrefix}/tools/visible-and-published`);
  },
  tool(id) {
    return axios.get(`/${endpointPrefix}/tools/${id}`);
  },
  visibility(id, data) {
    return axios.patch(`/${endpointPrefix}/tools/${id}/visibility`, data);
  },
  popular(id, data) {
    return axios.patch(`/${endpointPrefix}/tools/${id}/popular`, data);
  },
  multipleVisible(data) {
    return axios.post(`/${endpointPrefix}/tools/visibility`, data);
  },
  multiplePopular(data) {
    return axios.post(`/${endpointPrefix}/tools/popular`, data);
  },
  publish(id, data) {
    return axios.patch(`/${endpointPrefix}/tools/${id}/publish`, data);
  },
  update(id, data) {
    return axios.patch(`/${endpointPrefix}/tools/${id}`, data);
  },
  countNewToolsToday() {
    return axios.get(`/${endpointPrefix}/tools/new-tools/count`);
  },
  create(data) {
    return axios.post(`/${endpointPrefix}/tools/create`, data);
  }
}
