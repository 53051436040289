<template>
  <Button
    label="Place Order"
    icon="pi pi-plus"
    class="p-button-success mr-2"
    style="height: 3rem"
    @click="openNew"
  />

  <Dialog v-model:visible="itemDialog" :style="{width: '850px'}" header="Place Order" :modal="true" class="p-fluid">
    <!-- Tools -->
    <div class="field">
      <div class="p-float-label">
        <Dropdown id="tool" :options="tools" optionLabel="name" v-model="selectedTool" :filter="true"  optionGroupLabel="category" optionGroupChildren="items" />
        <label for="tool">Tool*</label>
      </div>

      <small
        v-if="(v$.selectedTool.$invalid && submitted)"
        class="p-error">{{ v$.selectedTool.required.$message.replace('Value', 'Tool') }}
      </small>
    </div>

    <!-- Info -->
    <div class="field" v-if="selectedTool">
      <p>Process Time: {{ selectedTool.process_time }}</p>
      <div v-html="selectedTool.description" style="margin-bottom: 1rem"></div>
      <p>Price (for 1 IMEI): {{ selectedTool.sell_price }} $</p>
    </div>

    <!-- IMEI -->
    <div class="field" v-if="selectedTool">
      <label for="imei">IMEI*</label>
      <Textarea
        id="imei" v-model.trim="v$.item.imei.$model" required="true"
        :class="{'p-invalid': v$.item.imei.$invalid && submitted}"
      />
      <small
        v-if="(v$.item.imei.$invalid && submitted) || v$.item.imei.$pending.$response"
        class="p-error">{{ v$.item.imei.required.$message.replace('Value', 'IMEI') }}
      </small>
    </div>

    <!-- Comment -->
    <div class="field" v-if="selectedTool">
      <label for="comment">Comment</label>
      <Textarea id="comment" v-model.trim="item.comment"/>
    </div>

    <template #footer>
      <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="hideDialog" />
      <Button label="Place" icon="pi pi-check" class="p-button-text" @click="saveItem(!v$.$invalid)" />
    </template>
  </Dialog>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import ToolResource from '@http/ToolResource';
import toastParamBuilder from "@/builders/ToastParamBuilder";
import {mapGetters} from "vuex";
import store from "@store";

export default {
  setup: () => ({v$: useVuelidate()}),

  emits: ["hideDialog"],

  data() {
    return {
      itemDialog: false,
      tools: [],
      selectedTool: null,
      item: {
        imei: '',
        comment: '',
        amount: 0,
      },
      submitted: false,
    }
  },
  computed: {
    ...mapGetters(
        'order', [
          'orders',
        ]
    ),
  },
  methods: {
    openNew() {
      this.selectedTool = null;

      ToolResource.visibleAndPublished()
        .then(({data}) => {
          this.tools = data;
        });

      this.submitted = false;
      this.itemDialog = true;
    },

    saveItem(isFormValid) {
      this.submitted = true;

      if (!isFormValid) {
        return;
      }

      this.item.tool_id = this.selectedTool.id;
      this.item.amount = this.selectedTool.sell_price;

      this.$store.dispatch('order/createOrder', this.item)
        .then(async ({data}) => {
          await store.dispatch('order/getOrders')
          this.$toast.add(toastParamBuilder.success(data));
          this.closeDialog();
        })
        .catch(({response}) => {
          this.$toast.add(toastParamBuilder.error(response.data));
          this.closeDialog();
        });
    },

    hideDialog() {
      this.itemDialog = false;
      this.submitted = false;
    },

    closeDialog() {
      this.itemDialog = false;
      this.item = {};
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          _r: Date.now(),
        }
      });
    },
  },

  validations() {
    return {
      item: {
        imei: { required },
      },
      selectedTool: { required },
    }
  },
}
</script>
<style>
  .p-dropdown-item {
    max-width: 100vw;
    text-overflow: ellipsis;
  }
  .p-dialog {
    max-width: 100vw;
  }
</style>
