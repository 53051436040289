import guest from "@/router/middleware/guest";

export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('@pages/auth/Login.vue'),
    meta: {
      middleware: guest
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@pages/auth/Register.vue'),
    meta: {
      middleware: guest
    },
  },
  {
    path: '/reset-password',
    name: 'reset_password',
    component: () => import('@pages/auth/ResetPassword.vue'),
    meta: {
      middleware: guest
    },
  },
  {
    path: '/forgot-password',
    name: 'forgot_password',
    component: () => import('@pages/auth/ForgotPassword.vue'),
    meta: {
      middleware: guest
    },
  },
];
