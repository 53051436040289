import axiosIns from '@axios';
import errorMessageBuilder from "@/builders/ErrorMessageBuilder";

const endpointPrefix = process.env.VUE_APP_API_PREFIX;

export default class UserResource {

  getUsers(params) {
    return axiosIns.get(`/${endpointPrefix}/users`, {params});
  }

  getUserSettings() {
    return axiosIns.get(`/${endpointPrefix}/auth/user-settings`);
  }

  setUserSettings(payload) {
    return axiosIns.post(`/${endpointPrefix}/auth/user-settings`, payload);
  }

  createUser(data) {
    return axiosIns.post(`/${endpointPrefix}/users`, data)
      .then(({ data }) => {
        return data;
      })
      .catch(error => {
        return Promise.reject(errorMessageBuilder(error));
      });
  }

  getUserById(id) {
    return axiosIns.get(`/${endpointPrefix}/users/` + id)
      .then(({ data }) => {
        return data;
      })
      .catch(error => {
        return Promise.reject(errorMessageBuilder(error));
      });
  }

  updateUser(id, data) {
    return axiosIns.patch(`/${endpointPrefix}/users/` + id, data)
      .then(({ data }) => {
        return data;
      })
      .catch(error => {
        return Promise.reject(errorMessageBuilder(error));
      });
  }

}
