<template>
  <div>
    <Toast/>
    <ProgressBar
      v-if="$loading.show"
      mode="indeterminate"
    />
    <router-view />
  </div>
</template>

<script>
import EventBus from './AppEventBus';

export default {
  themeChangeListener: null,

  mounted() {
    this.themeChangeListener = (event) => {
      const elementId = 'theme-link';
      const linkElement = document.getElementById(elementId);
      const cloneLinkElement = linkElement.cloneNode(true);
      const newThemeUrl = linkElement.getAttribute('href').replace(this.$appState.themeName, event.themeName);

      cloneLinkElement.setAttribute('id', elementId + '-clone');
      cloneLinkElement.setAttribute('href', newThemeUrl);
      cloneLinkElement.addEventListener('load', () => {
        linkElement.remove();
        cloneLinkElement.setAttribute('id', elementId);
      });
      linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

      this.$appState.themeName = event.themeName;
      this.$appState.isDarkTheme = event.isDarkTheme;
    };

    EventBus.on('theme-change', this.themeChangeListener);
  },

  beforeUnmount() {
    EventBus.off('theme-change', this.themeChangeListener);
  },
  beforeCreate() {
    this.$store.commit('initialiseStore');
  },
}
</script>

<style scoped lang="scss">
.p-progressbar.p-component.p-progressbar-indeterminate {
  height: 0.3em;
  position: fixed;
  z-index: 998;
  left: -5px;
  right: -5px;
  top: 0;
  width: auto;
}
</style>
