import {zelle} from "@http/PaymentMethodResource";

export default {
  namespaced: true,
  state: {
    zellePaymentRequests: null,
    pagination: null,
  },
  getters: {
    getZellePaymentRequests(state) {
      return state.zellePaymentRequests;
    },
    getPagination(state) {
      return state.pagination;
    },
  },
  mutations: {
    setZellePaymentRequests(state, payload) {
      state.zellePaymentRequests = payload;
    },
    setPagination(state, payload) {
      state.pagination = payload;
    },
  },
  actions: {
    zellePaymentRequests({commit}, params) {
      return zelle.getRequests(params)
        .then(response => {
          commit('setZellePaymentRequests', response.data?.data);
          commit('setPagination', response.data?.meta);
          return Promise.resolve(response);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    zellePaymentRequestsAllCompanies({commit}, params) {
      return zelle.getRequestsAllCompanies(params)
        .then(response => {
          commit('setZellePaymentRequests', response.data?.data);
          commit('setPagination', response.data?.meta);
          return Promise.resolve(response);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    approve(_, id) {
      return zelle.approveRequest(id)
        .then(response => {
          return Promise.resolve(response);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    reject(_, id) {
      return zelle.rejectRequest(id)
        .then(response => {
          return Promise.resolve(response);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    show(_, id) {
      return zelle.showRequest(id)
        .then(response => {
          return Promise.resolve(response);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    update(_, {id, data}) {
      return zelle.updateRequest(id, data)
        .then(response => {
          return Promise.resolve(response);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    }
  },
}
