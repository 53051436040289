<template>
  <div class="layout-menu-container">
    <ul class="p-menubar-root-list flex flex-nowrap text-sm mobile-menu" role="menubar">
      <li class="p-menuitem-blue">
        <div :class="isDarkTheme ? 'p-menuitem-dark' : 'p-menuitem-light'">
              <span class="p-menuitem-text">
                Balance: {{getCompany?.balance}} $
              </span>
        </div>
      </li>
      <li class="p-menuitem-green">
        <div :class="isDarkTheme ? 'p-menuitem-dark' : 'p-menuitem-light'">
              <span class="p-menuitem-text">
                Frozen: {{getCompany?.frozen_balance}} $
              </span>
        </div>
      </li>
    </ul>
    <AppSubmenu :items="model" class="layout-menu" :root="true" @menuitem-click="onMenuItemClick" @keydown="onKeyDown"/>
    <div class="absolute bottom-0 pb-3">
      <ul class="bottom-menu">
        <li><a href="">Q&A</a></li>
        <li><a href="">Terms & Conditions</a></li>
      </ul>
    </div>
  </div>
</template>

<script>
import AppSubmenu from './AppSubmenu';
import {mapGetters} from "vuex";

export default {
  props: {
    model: Array
  },
  methods: {
    onMenuItemClick(event) {
      this.$emit('menuitem-click', event);
    },
    onKeyDown(event) {
      const nodeElement = event.target;
      if (event.code === 'Enter' || event.code === 'Space') {
        nodeElement.click();
        event.preventDefault();
      }
    },
  },
  computed: {
    darkTheme() {
      return this.$appState.darkTheme;
    },
    ...mapGetters({
      getCompany: 'company/getCompany'
    })
  },
  components: {
    'AppSubmenu': AppSubmenu
  }
}
</script>

<style scoped lang="scss">
  .bottom-menu {
    display: flex;
    margin: 0;
    padding: 0;
    li {
      list-style-type: none;
      margin-right: 20px;
    }
  }
  .mobile-menu {
    display: none !important;
  }
  @media (max-width: 991px) {
    .mobile-menu {
      display: flex !important;
    }
  }
</style>
