export default [
  {
    path: '/error',
    name: 'error',
    component: () => import('@pages/errors/Error.vue')
  },
  {
    path: '/notfound',
    name: 'notfound',
    component: () => import('@pages/errors/NotFound.vue')
  },
  {
    path: '/access-denied',
    name: 'access_denied',
    component: () => import('@pages/errors/AccessDenied.vue')
  }
];
