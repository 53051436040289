import ApiProviderResource from '@http/ApiProviderResource';

const apiProviderResource = new ApiProviderResource();

export default {
  namespaced: true,

  state: {
    apiProviders: [],
    pagination: {},
    lazyParams: {},
  },

  actions: {
    getApiProviders({commit}, params) {
      return apiProviderResource.getApiProviders(params)
        .then(({data}) => {
          commit('setAll', data.data);
          commit('setPagination', data.meta);

          return Promise.resolve(true);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },

    createApiProvider(_, payload) {
      return apiProviderResource.createApiProvider(payload)
        .then(response => {
          return Promise.resolve(response);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },

    updateApiProvider(_, {id, payload}) {
      return apiProviderResource.updateApiProvider(id, payload)
        .then(response => {
          return Promise.resolve(response);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },

    updateApiProviderStatus(_, {id, is_active}) {
      return apiProviderResource.updateApiProviderStatus(id, is_active)
        .then(response => {
          return Promise.resolve(response);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    }
  },

  mutations: {
    setAll(state, apiProviders) {
      state.apiProviders = apiProviders;
    },

    setPagination(state, page) {
      state.pagination = page;
    },

    setLazyParams(state, lazyParams) {
      state.lazyParams = lazyParams;
    },
  },

  getters: {
    apiProviders(state) {
      return state.apiProviders;
    },

    pagination(state) {
      return state.pagination;
    },

    lazyParams(state) {
      return state.lazyParams;
    },
  }

};
