import toolResource from "@/http/ToolResource";

export default {
  namespaced: true,
  state: {
    tools: null,
    pagination: null,
    countNewToolsToday: null,
  },
  getters: {
    getTools(state) {
      return state.tools;
    },
    getPagination(state) {
      return state.pagination;
    },
    getCountNewToolsToday(state) {
      return state.countNewToolsToday;
    },
  },
  mutations: {
    setTools(state, payload) {
      state.tools = payload;
    },
    setPagination(state, payload) {
      state.pagination = payload;
    },
    setCountNewToolsToday(state, payload) {
      state.countNewToolsToday = payload;
    },
  },
  actions: {
    newTools({commit}, params) {
      return toolResource.newTools(params)
        .then(response => {
          commit('setTools', response.data?.data);
          commit('setPagination', response.data?.meta);
          return Promise.resolve(response);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    pricesAndTools({commit}, params) {
      return toolResource.pricesAndTools(params)
        .then(response => {
          commit('setTools', response.data?.data);
          commit('setPagination', response.data?.meta);
          return Promise.resolve(response);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    countNewToolsToday({commit}) {
      return toolResource.countNewToolsToday()
        .then(({data}) => {
          commit('setCountNewToolsToday', data.count);
        });
    },
    createTool(_, data) {
      return toolResource.create(data).then(response => {
        return Promise.resolve(response);
        }).catch(error => {
        return Promise.reject(error);
      });
    },
  },
}
