import { createRouter, createWebHistory } from 'vue-router';
import store from "@/store";
import authMiddleware from '@/router/middleware/auth'

import apps from './routes/apps';
import errors from './routes/errors';
import auth from './routes/auth';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    apps,
    ...auth,
    ...errors,
    {
      path: "/:catchAll(.*)",
      redirect: 'notfound',
    },
  ],
});

router.beforeEach(function(to, from, next) {
  if (!to.meta.middleware) {
    return authMiddleware({next, store})
  }

  return to.meta.middleware({next, store});
});

router.beforeResolve(async to => {
  if (to.query._r) {
    let query = Object.assign({}, to.query);
    delete query._r;
    await router.replace({name: to.name, query});
  }
});

export default router;
