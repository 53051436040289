import user from '@/models/User';

export default function admin({next, store}) {
  if (!store.getters['auth/isUserLoggedIn']) {
    return next({name: 'login'});
  }

  if (store.getters['auth/getRole'] !== user.ROLE_SUPER_ADMIN) {
    return next({name: 'access_denied'});
  }

  return next();
}
