import axiosIns from '@axios';
import errorMessageBuilder from "@/builders/ErrorMessageBuilder";

const endpointPrefix = process.env.VUE_APP_API_PREFIX;

export default class UserResource {

  getApiProviders(params) {
    return axiosIns.get(`/${endpointPrefix}/api-providers`, {params});
  }

  createApiProvider(data) {
    return axiosIns.post(`/${endpointPrefix}/api-providers`, data)
      .then(({ data }) => {
        return data;
      })
      .catch(error => {
        return Promise.reject(errorMessageBuilder(error));
      });
  }

  getApiProviderById(id) {
    return axiosIns.get(`/${endpointPrefix}/api-providers/` + id)
      .then(({ data }) => {
        return data;
      })
      .catch(error => {
        return Promise.reject(errorMessageBuilder(error));
      });
  }

  updateApiProvider(id, data) {
    return axiosIns.patch(`/${endpointPrefix}/api-providers/` + id, data)
      .then(({ data }) => {
        return data;
      })
      .catch(error => {
        return Promise.reject(errorMessageBuilder(error));
      });
  }

  updateApiProviderStatus(id, is_active) {
    return axiosIns.patch(`/${endpointPrefix}/api-providers/status/` + id, { is_active })
      .then(({ data }) => {
        return data;
      })
      .catch(error => {
        return Promise.reject(errorMessageBuilder(error));
      });
  }

}
