import user from '@/models/User';

export default function adminOrSuperAdmin({next, store}) {
  if (!store.getters['auth/isUserLoggedIn']) {
    return next({name: 'login'});
  }

  if (! [user.ROLE_SUPER_ADMIN, user.ROLE_ADMIN].includes(store.getters['auth/getRole'])) {
    return next({name: 'access_denied'});
  }

  return next();
}
