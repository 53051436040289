import companyResource from "@/http/CompanyResource";

export default {
  namespaced: true,
  state: {
    company: null,
    companies: null,
    pagination: null,
    countActiveRequests: null,
  },
  getters: {
    getCompany(state) {
      return state.company;
    },
    getCompanies(state) {
      return state.companies;
    },
    getPagination(state) {
      return state.pagination;
    },
    getCountActiveRequests(state) {
      return state.countActiveRequests;
    },
  },
  mutations: {
    setCompany(state, payload) {
      state.company = payload;
    },
    setCompanies(state, payload) {
      state.companies = payload;
    },
    setPagination(state, payload) {
      state.pagination = payload;
    },
    setCountActiveRequests(state, payload) {
      state.countActiveRequests = payload;
    },
  },
  actions: {
    companies({commit}, params) {
      return companyResource.companies(params)
        .then(response => {
          commit('setCompanies', response.data?.data);
          commit('setPagination', response.data?.meta);
          return Promise.resolve(response);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    activeRequests({commit}, params) {
      return companyResource.activeRequests(params)
        .then(response => {
          commit('setCompanies', response.data?.data);
          commit('setPagination', response.data?.meta);
          return Promise.resolve(response);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    declinedRequests({commit}, params) {
      return companyResource.declinedRequests(params)
        .then(response => {
          commit('setCompanies', response.data?.data);
          commit('setPagination', response.data?.meta);
          return Promise.resolve(response);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    company({commit}) {
      return companyResource.authCompany()
        .then(({data}) => {
          commit('setCompany', data);
        });
    },
    countActiveRequests({commit}) {
      return companyResource.countActiveRequests()
        .then(({data}) => {
          commit('setCountActiveRequests', data.count);
        });
    }
  },
}
