<template>
  <div class="layout-topbar flex justify-content-between">
    <div class="flex align-items-center">
      <router-link to="/" class="layout-topbar-logo">
        <img alt="Logo" :src="topbarImage()" />
      </router-link>

      <button class="p-link layout-menu-button layout-topbar-button"  @click="onMenuToggle">
        <i class="pi pi-bars"></i>
      </button>

      <create-order />
    </div>

    <div class="flex justify-content-center" v-if="isAdminOrSuperAdmin">
      <div class="p-menubar p-menubar-wrapper p-component layout-topbar-menu">
        <ul class="p-menubar-root-list" role="menubar">
          <li class="p-menuitem-blue">
            <div :class="isDarkTheme ? 'p-menuitem-dark' : 'p-menuitem-light'">
              <span class="p-menuitem-text">
                Balance: {{getCompany?.balance}} $
              </span>
            </div>
          </li>
          <li class="p-menuitem-green">
            <div :class="isDarkTheme ? 'p-menuitem-dark' : 'p-menuitem-light'">
              <span class="p-menuitem-text">
                Frozen Balance: {{getCompany?.frozen_balance}} $
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="flex justify-content-end mobile-top-elements">
      <vue-feather
        class="theme-icon mr-3"
        :type="isDarkTheme ? 'sun' : 'moon'"
        @click="changeTheme"
      />
<!--      <a href="/profile" class="p-menuitem-link" tabindex="-1" aria-hidden="true">-->
<!--        <span class="p-menuitem-icon pi pi-fw pi-user"></span>-->
<!--      </a>-->
<!--      <a href="/profile" class="p-menuitem-link" tabindex="-1" aria-hidden="true">-->
<!--        <span class="p-menuitem-icon pi pi-fw pi-sign-out"></span>-->
<!--      </a>-->
      <Menubar
        :model="menuItems"
        class="layout-topbar-menu"
      />
    </div>
  </div>
</template>

<script>
import CreateOrder from "@/pages/app/Orders/CreateOrder";
import store from "@/store";
import router from "@/router";
import user from "@models/User";
import { mapGetters } from "vuex";
import EventBus from './AppEventBus';
import themes from "@/models/Themes";

export default {
  components: {
    CreateOrder,
  },

  data() {
    return {
      menuItems: [
        {
          label: 'Profile',
          icon:'pi pi-fw pi-user',
          to: '/profile',
        },
        {
          label: 'Quit',
          icon:'pi pi-fw pi-sign-out',
          command: () => {
            store.dispatch('auth/logout');
            router.push({name: 'login'});
          }
        },
      ],
    }
  },

  mounted() {
    EventBus.emit('theme-change', this.theme);
  },

  methods: {
    changeTheme() {
      const theme = this.isDarkTheme ? themes.light : themes.dark;
      store.dispatch('userSettings/set', {theme});
    },

    onMenuToggle(event) {
      this.$emit('menu-toggle', event);
    },

    onTopbarMenuToggle(event) {
      this.$emit('topbar-menu-toggle', event);
    },

    topbarImage() {
      return this.isDarkTheme ? '/images/gsm-logo-dark.png' : '/images/gsm-logo.png';
    },
  },

  computed: {
    ...mapGetters({
      getCompany: 'company/getCompany',
      userRole: 'auth/getRole',
      theme: 'userSettings/theme',
    }),

    isDarkTheme() {
      return this.theme.isDarkTheme;
    },

    isAdminOrSuperAdmin() {
      return [user.ROLE_SUPER_ADMIN, user.ROLE_ADMIN].includes(this.userRole);
    },
  }
}
</script>

<style>
  .mobile-top-elements {
    height:
  }
  .theme-icon {
    margin: 0 0 0 auto;
    padding: 0;
    display: flex!important;
  }

  .theme-icon:hover {
    cursor: pointer;
  }

  .p-menubar-wrapper {
    padding: 0.5rem;
    background: none!important;
    color: #495057;
    border: none!important;
    border-radius: 6px;
    list-style: none!important;
  }

  .p-menuitem-blue {
    background-color: rgb(30,144,255,0.7);
    border-radius: 6px;
    margin-right: 10px;
  }

  .p-menuitem-green {
    background-color: rgb(127,255,0,0.7);
    border-radius: 6px;
  }

  .p-menuitem-light {
    padding: 0.75rem 1.25rem;
    color: #0b141d;
    border-radius: 0;
    transition: box-shadow 0.2s;
    user-select: none;
  }

  .p-menuitem-dark {
    padding: 0.75rem 1.25rem;
    color: rgba(255, 255, 255, 0.87);
    border-radius: 0;
    transition: box-shadow 0.2s;
    user-select: none;
  }
</style>
