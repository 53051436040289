<template>
  <div :class="containerClass" @click="onWrapperClick">
    <AppTopBar @menu-toggle="onMenuToggle"/>
    <div class="layout-sidebar" @click="onSidebarClick">
      <AppMenu :model="menu" @menuitem-click="onMenuItemClick"/>
    </div>

    <div class="layout-main-container">
      <div class="layout-main">
        <router-view/>
      </div>
    </div>

<!--    <AppConfig :layoutMode="layoutMode" @layout-change="onLayoutChange"/>-->
    <transition name="layout-mask">
      <div class="layout-mask p-component-overlay" v-if="mobileMenuActive"></div>
    </transition>
  </div>
</template>

<script>
import AppTopBar from './AppTopbar.vue';
import AppMenu from './AppMenu.vue';
// import AppConfig from './AppConfig.vue';

import user from '@models/User';
import {mapActions, mapGetters} from "vuex";
import JivochatWidget from "@/services/JivochatWidget";

export default {
  emits: ['change-theme'],

  components: {
    'AppTopBar': AppTopBar,
    'AppMenu': AppMenu,
    // 'AppConfig': AppConfig,
  },

  data() {
    return {
      layoutMode: 'static',
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
    }
  },

  watch: {
    $route() {
      this.menuActive = false;
    }
  },

  created() {
    this.watchExpiresInTime();
    this.user();
    if (this.userRole === user.ROLE_SUPER_ADMIN) {
      this.countActiveRequests();
      this.countNewToolsToday();
    }
    if ([user.ROLE_SUPER_ADMIN, user.ROLE_ADMIN].includes(this.userRole)) {
      this.company();
    }
    JivochatWidget.run();
  },

  methods: {
    ...mapActions({
      countActiveRequests: 'company/countActiveRequests',
      countNewToolsToday: 'tool/countNewToolsToday',
      company: 'company/company',
      user: 'auth/user',
      watchExpiresInTime: 'auth/watchExpiresInTime',
    }),
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }

      this.menuClick = false;
    },
    onMenuToggle() {
      this.menuClick = true;

      if (this.isDesktop()) {
        if (this.layoutMode === 'overlay') {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }

          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        } else if (this.layoutMode === 'static') {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }

      event.preventDefault();
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
    },
    addClass(element, className) {
      if (element.classList)
        element.classList.add(className);
      else
        element.className += ' ' + className;
    },
    removeClass(element, className) {
      if (element.classList)
        element.classList.remove(className);
      else
        element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    },
    isDesktop() {
      return window.innerWidth >= 992;
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === 'static')
          return !this.staticMenuInactive;
        else if (this.layoutMode === 'overlay')
          return this.overlayMenuActive;
      }

      return true;
    }
  },

  computed: {
    ...mapGetters({
      getCountActiveRequests: 'company/getCountActiveRequests',
      getCountNewToolsToday: 'tool/getCountNewToolsToday',
      userRole: 'auth/getRole'
    }),
    containerClass() {
      return ['layout-wrapper', {
        'layout-overlay': this.layoutMode === 'overlay',
        'layout-static': this.layoutMode === 'static',
        'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
        'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
        'layout-mobile-sidebar-active': this.mobileMenuActive,
        'p-input-filled': this.$primevue.config.inputStyle === 'filled',
        'p-ripple-disabled': this.$primevue.config.ripple === false
      }];
    },
    menu() {
      return [
        {
          label: 'Home',
          role: [user.ROLE_SUPER_ADMIN, user.ROLE_ADMIN],
          items: [
            {label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/dashboard', role: [user.ROLE_SUPER_ADMIN, user.ROLE_ADMIN]}
          ]
        },
        {
          label: 'Pages', icon: 'pi pi-fw pi-clone',
          items: [
            // {label: 'Users', icon: 'pi pi-fw pi-user-edit', to: '/users', role: user.ROLE_ADMIN},
            {
              label: 'Companies',
              icon: 'pi pi-fw pi-users',
              to: '/companies',
              role: user.ROLE_SUPER_ADMIN,
              count: this.getCountActiveRequests,
              items: [
                {label: 'Active Requests', icon: 'pi pi-fw pi-users', to: '/companies/active-requests', role: user.ROLE_SUPER_ADMIN},
                {label: 'Disabled Companies', icon: 'pi pi-fw pi-users', to: '/companies/disabled-companies', role: user.ROLE_SUPER_ADMIN},
              ]
            },
            {label: 'Api Providers', icon: 'pi pi-fw pi-user-edit', to: '/api-providers', role: user.ROLE_SUPER_ADMIN},
            {label: 'New Tools', icon: 'pi pi-fw pi-paperclip', to: '/new-tools', role: user.ROLE_SUPER_ADMIN, count: this.getCountNewToolsToday},
            {label: 'Prices and Tools', icon: 'pi pi-fw pi-briefcase', to: '/prices-and-tools', role: user.ROLE_SUPER_ADMIN},
            {label: 'Personal Prices', icon: 'pi pi-fw pi-money-bill', to: '/personal-prices', role: user.ROLE_SUPER_ADMIN},
            {label: 'Order history', icon: 'pi pi-fw pi-unlock', to: '/orders'},
            {label: 'Transaction history', icon: 'pi pi-fw pi-history', to: '/transactions', roles: [user.ROLE_SUPER_ADMIN, user.ROLE_ADMIN]},
            {label: 'Telegram', icon: 'pi pi-fw pi-telegram', target: '_blank', url: 'https://t.me/joinchat/RLG761htaAvneAgs', roles: [user.ROLE_SUPER_ADMIN, user.ROLE_ADMIN]},
            {
              label: 'Payment Methods',
              icon: 'pi pi-fw pi-wallet',
              role: user.ROLE_SUPER_ADMIN,
              items: [
                {label: 'Zelle', icon: 'pi pi-fw pi-wallet', to: '/payment-methods/zelle', role: user.ROLE_SUPER_ADMIN},
                {label: 'GoChyp', icon: 'pi pi-fw pi-wallet', to: '/payment-methods/go-chyp', role: user.ROLE_SUPER_ADMIN},
              ]
            },
            {
              label: 'Add Credits',
              icon: 'pi pi-fw pi-credit-card',
              roles: [user.ROLE_SUPER_ADMIN, user.ROLE_ADMIN],
              paymentMethods: [user.PAYMENT_METHOD_ZELLE, user.PAYMENT_METHOD_GO_CHYP],
              items: [
                {label: 'Zelle', icon: 'pi pi-fw pi-credit-card', to: '/add-credits/zelle', roles: [user.ROLE_SUPER_ADMIN, user.ROLE_ADMIN], paymentMethod: user.PAYMENT_METHOD_ZELLE},
                {label: 'GoChyp', icon: 'pi pi-fw pi-credit-card', to: '/add-credits/go-chyp', roles: [user.ROLE_SUPER_ADMIN, user.ROLE_ADMIN], paymentMethod: user.PAYMENT_METHOD_GO_CHYP},
              ]
            },
          ]
        },
      ];
    }
  },

  beforeUpdate() {
    if (this.mobileMenuActive) {
      this.addClass(document.body, 'body-overflow-hidden');
    } else {
      this.removeClass(document.body, 'body-overflow-hidden');
    }
  },
}
</script>

<style lang="scss">
@import './App.scss';
</style>
