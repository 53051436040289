export default {
  run() {
    let script = document.createElement('script');
    script.setAttribute('src', '//code.jivosite.com/widget/' + process.env.VUE_APP_JIVOCHAT_WIDGET_CODE);
    script.setAttribute('async', 'async');
    document.head.appendChild(script);
  },
  remove() {
    if (window?.jivo_version) {
      window.jivo_destroy();
    }
  }
}
