import axios from "@/libs/axios";

const endpointPrefix = process.env.VUE_APP_API_PREFIX;

export default {
  companies(params) {
    return axios.get(`/${endpointPrefix}/companies`, { params });
  },
  activeRequests(params) {
    return axios.get(`/${endpointPrefix}/companies/active-requests`, { params });
  },
  declinedRequests(params) {
    return axios.get(`/${endpointPrefix}/companies/disabled-companies`, { params });
  },
  company(id) {
    return axios.get(`/${endpointPrefix}/companies/${id}`);
  },
  update(id, data) {
    return axios.patch(`/${endpointPrefix}/companies/${id}`, {
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      phone: data.phone,
      language: data.language,
      business_address: data.businessAddress,
      business_name: data.businessName,
      business_type: data.businessType,
      facebook_url: data.facebookUrl,
      instagram_url: data.instagramUrl,
      linkedin_url: data.linkedinUrl,
    });
  },
  approve(id) {
    return axios.patch(`/${endpointPrefix}/companies/${id}/approve`);
  },
  reject(id, data) {
    return axios.patch(`/${endpointPrefix}/companies/${id}/reject`, data);
  },
  updateBalance(id, amount, type = 'increment') {
    return axios.patch(`/${endpointPrefix}/companies/${id}/updateBalance`, { balance: amount, type: type });
  },
  getUsersByCompany(id, params) {
    return axios.get(`/${endpointPrefix}/companies/${id}/users`, { params })
  },
  allCompanies() {
    return axios.get(`/${endpointPrefix}/companies/all`);
  },
  companiesWhichHasOrders() {
    return axios.get(`/${endpointPrefix}/companies/has-orders`);
  },
  authCompany() {
    return axios.get(`/${endpointPrefix}/auth/company`);
  },
  countActiveRequests() {
    return axios.get(`/${endpointPrefix}/companies/count-active-requests`);
  },
}
