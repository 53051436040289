
export default {
  dark: {
    themeName: "lara-dark-indigo",
    isDarkTheme: true,
  },
  light: {
    themeName: "lara-light-indigo",
    isDarkTheme: false,
  },
};
