import axios from "@/libs/axios";

const endpointPrefix = process.env.VUE_APP_API_PREFIX;

export const zelle = {
  get() {
    return axios.get(`/${endpointPrefix}/payment-methods/zelle`);
  },
  update(data) {
    return axios.post(`/${endpointPrefix}/payment-methods/zelle`, data);
  },
  getRequestsAllCompanies(params) {
    return axios.get(`/${endpointPrefix}/payment-methods/zelle/request/companies`, {params});
  },
  getRequests(params) {
    return axios.get(`/${endpointPrefix}/payment-methods/zelle/request`, {params});
  },
  createRequest(data) {
    return axios.post(`/${endpointPrefix}/payment-methods/zelle/request`, data);
  },
  approveRequest(id) {
    return axios.post(`/${endpointPrefix}/payment-methods/zelle/request/${id}/approve`);
  },
  rejectRequest(id) {
    return axios.post(`/${endpointPrefix}/payment-methods/zelle/request/${id}/reject`);
  },
  showRequest(id) {
    return axios.get(`/${endpointPrefix}/payment-methods/zelle/request/${id}`);
  },
  updateRequest(id, data) {
    return axios.post(`/${endpointPrefix}/payment-methods/zelle/request/${id}`, {
      amount: data.amount,
      transaction_id: data.transactionId
    });
  },
  getPermissionCompanies() {
    return axios.get(`/${endpointPrefix}/payment-methods/zelle/permission`);
  },
  savePermissionCompany(data) {
    return axios.post(`/${endpointPrefix}/payment-methods/zelle/permission`, data);
  },
};

export const goChyp = {
  get() {
    return axios.get(`/${endpointPrefix}/payment-methods/go-chyp`);
  },
  update(data) {
    return axios.post(`/${endpointPrefix}/payment-methods/go-chyp`, data);
  },
  getRequest() {
    return axios.get(`/${endpointPrefix}/payment-methods/go-chyp/request`);
  },
  createRequest(data) {
    return axios.post(`/${endpointPrefix}/payment-methods/go-chyp/request`, data);
  },
  getPermissionCompany() {
    return axios.get(`/${endpointPrefix}/payment-methods/go-chyp/permission`);
  },
  savePermissionCompany(data) {
    return axios.post(`/${endpointPrefix}/payment-methods/go-chyp/permission`, data);
  },
};
