import themes from "@/models/Themes";
import UserResource from "@/http/UserResource";
import EventBus from '../../AppEventBus';

const userResource = new UserResource();

export default {
  namespaced: true,

  state: {
    theme: {},
  },

  actions: {
    set({commit}, payload) {
      userResource.setUserSettings({settings: payload})
        .then(() => {
          commit('set', payload);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
  },

  mutations: {
    set(state, payload) {
      const settings = payload ? payload : {theme: themes.light};
      state.theme = settings.theme;
      EventBus.emit('theme-change', settings.theme);
    },
  },

  getters: {
    theme(state) {
      return state.theme;
    },
  },
}
