import axios from "@/libs/axios";
import errorMessageBuilder from "@/builders/ErrorMessageBuilder";

const endpointPrefix = process.env.VUE_APP_API_PREFIX;

export default {
  registerCompany(data) {
    return axios.post(`/${endpointPrefix}/auth/register`, {
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      password: data.password,
      password_confirmation: data.confirmPassword,
      phone: data.phone,
      language: data.language,
      business_address: data.businessAddress,
      business_name: data.businessName,
      business_type: data.businessType,
      facebook_url: data.facebookUrl,
      instagram_url: data.instagramUrl,
      linkedin_url: data.linkedinUrl,
    });
  },
  login(data) {
    return axios.post('/oauth/token', {
      username: data.email,
      password: data.password,
      grant_type: 'password',
      client_id: process.env.VUE_APP_API_CLIENT_ID,
      client_secret: process.env.VUE_APP_API_CLIENT_SECRET,
    });
  },
  refresh(refreshToken) {
    return axios.post('/oauth/token', {
      refresh_token: refreshToken,
      grant_type: 'refresh_token',
      client_id: process.env.VUE_APP_API_CLIENT_ID,
      client_secret: process.env.VUE_APP_API_CLIENT_SECRET,
    }, {_refresh: true});
  },
  user() {
    return axios.get(`/${endpointPrefix}/auth/user`);
  },
  resetPassword(data) {
    return axios.post(`/${endpointPrefix}/auth/reset-password`, data)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        return Promise.reject(errorMessageBuilder(error));
      });
  },
  forgotPassword(data) {
    return axios.post(`/${endpointPrefix}/auth/forgot-password`, data)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        return Promise.reject(errorMessageBuilder(error));
      });
  }
}
