
export default new class User {
  statuses = [
    { id: 0, name: 'inactive' },
    { id: 1, name: 'active' },
  ]

  ROLE_SUPER_ADMIN = 'Super Admin'
  ROLE_ADMIN = 'Admin'
  ROLE_USER = 'User'

  PAYMENT_METHOD_ZELLE = 'Zelle'
  PAYMENT_METHOD_GO_CHYP = 'GoChyp'

  roles = [this.ROLE_SUPER_ADMIN, this.ROLE_ADMIN, this.ROLE_USER]

  paymentMethods = [this.PAYMENT_METHOD_ZELLE, this.PAYMENT_METHOD_GO_CHYP]
};
