import authResource from "@/http/AuthResource";
import JivochatWidget from "@/services/JivochatWidget";
import axiosIns from "@axios";

let waitTimeExpireTimeoutId = null;

export default {
  namespaced: true,
  state: {
    tokens: {
      tokenType: null,
      expiresIn: null,
      accessToken: null,
      refreshToken: null,
      createdAt: null,
    },
    user: null
  },
  getters: {
    getAccessTokenWithType(state) {
      return state.tokens.tokenType + ' ' + state.tokens.accessToken;
    },
    getRefreshToken(state) {
      return state.tokens.refreshToken;
    },
    getRole(state) {
      return state.user?.role;
    },
    getPaymentMethods(state) {
      return state.user.payment_methods;
    },
    isUserLoggedIn(state) {
      return state.user && state.tokens.accessToken;
    },
    getExpiresInTime(state) {
      return state.tokens ? state.tokens.createdAt + (state.tokens.expiresIn * 1000) : null;
    },
  },
  mutations: {
    setTokens(state, payload) {
      state.tokens.tokenType = payload?.token_type;
      state.tokens.expiresIn = payload?.expires_in;
      state.tokens.accessToken = payload?.access_token;
      state.tokens.refreshToken = payload?.refresh_token;
      state.tokens.createdAt = payload ? (new Date()).getTime() : null;
    },
    setUser(state, payload) {
      state.user = payload ?? null;
    },
  },
  actions: {
    registerCompanyAndAdmin(_, formData) {
      return authResource.registerCompany(formData)
        .then((response) => {
          return Promise.resolve(response);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    login({commit}, formData) {
      axiosIns._refreshError = false;
      return authResource.login(formData)
        .then((response) => {
          commit('setTokens', response.data);
          return Promise.resolve(response);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    refresh({commit, getters, dispatch}) {
      if (getters.getRefreshToken) {
        axiosIns._refresh = true;
        return authResource.refresh(getters.getRefreshToken)
          .then((response) => {
            commit('setTokens', response.data);
            dispatch('watchExpiresInTime');
            return Promise.resolve(response);
          })
          .catch((error) => {
            return Promise.reject(error);
          })
          .finally(() => {
            axiosIns._refresh = false;
          });
      }
    },
    user({commit}) {
      return authResource.user()
        .then((response) => {
          commit('setUser', response.data);
          commit('userSettings/set', JSON.parse(response.data.settings), { root: true });
          return Promise.resolve(response);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    logout({commit}) {
      commit('setUser', null);
      commit('setTokens', null);
      JivochatWidget.remove();
    },
    watchExpiresInTime({dispatch, getters}) {
      if (waitTimeExpireTimeoutId) {
        clearTimeout(waitTimeExpireTimeoutId);
      }

      let time = getters['getExpiresInTime'] - (new Date()).getTime() - 30000;

      if (time > 0) {
        waitTimeExpireTimeoutId = setTimeout(() => {
          dispatch('refresh');
        }, time);
      }
    }
  },
}
