import App from "@/App";
// import admin from "@/router/middleware/admin";
import superAdmin from "@/router/middleware/superAdmin";
import adminOrSuperAdmin from "@/router/middleware/adminOrSuperAdmin";
import auth from "@/router/middleware/auth";

export default {
  path: '/',
  name: 'app',
  component: App,
  children: [
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@pages/app/Profile/Profile.vue'),
      meta: {
        middleware: auth
      },
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import('@pages/app/Dashboard.vue'),
      meta: {
        middleware: adminOrSuperAdmin
      },
    },
    {
      path: '/personal-prices',
      name: 'personal_prices',
      component: () => import('@pages/app/PersonalPrices/PersonalPrices.vue'),
      meta: {
        middleware: superAdmin
      },
    },
    {
      path: '/orders',
      name: 'orders',
      component: () => import('@pages/app/Orders/Orders.vue'),
      meta: {
        middleware: auth
      },
    },
    {
      path: '/api-providers',
      name: 'api_providers',
      component: () => import('@pages/app/ApiProviders/ApiProviders.vue'),
      meta: {
        middleware: superAdmin
      },
    },
    {
      path: '/new-tools',
      name: 'new-tools',
      component: () => import('@pages/app/NewTools/Table'),
      meta: {
        middleware: superAdmin
      },
    },
    {
      path: '/prices-and-tools',
      name: 'prices-and-tools',
      component: () => import('@pages/app/PricesAndTools/Table.vue'),
      meta: {
        middleware: superAdmin
      },
    },
    {
      path: '/prices-and-tools/new',
      name: 'prices-and-tools-new',
      component: () => import('@pages/app/PricesAndTools/New.vue'),
      meta: {
        middleware: superAdmin
      }
    },
    {
      path: '/users',
      name: 'users',
      component: () => import('@pages/app/Users/Users.vue'),
      meta: {
        middleware: superAdmin
      },
    },
    {
      path: '/companies',
      component: () => import('@pages/app/Companies/Index.vue'),
      meta: {
        middleware: superAdmin
      },
      children: [
        {
          path: '',
          name: 'companies',
          component: () => import('@pages/app/Companies/Companies.vue'),
          meta: {
            middleware: superAdmin
          },
        },
        {
          path: 'active-requests',
          name: 'companies-active-requests',
          component: () => import('@pages/app/Companies/ActiveRequests.vue'),
          meta: {
            middleware: superAdmin
          },
        },
        {
          path: 'disabled-companies',
          name: 'companies-disabled-companies',
          component: () => import('@pages/app/Companies/DisabledCompanies.vue'),
          meta: {
            middleware: superAdmin
          },
        },
        {
          path: ':id/view',
          name: 'company',
          component: () => import('@pages/app/Companies/Company.vue'),
          meta: {
            middleware: superAdmin
          },
        },
      ]
    },
    {
      path: '/payment-methods',
      name: 'payment-methods',
      redirect: {name: 'payment-methods-zelle'},
      component: () => import('@pages/app/PaymentMethods/Index.vue'),
      meta: {
        middleware: superAdmin
      },
      children: [
        {
          path: 'zelle',
          name: 'payment-methods-zelle',
          component: () => import('@pages/app/PaymentMethods/Zelle.vue'),
          meta: {
            middleware: superAdmin
          },
        },
        {
          path: 'go-chyp',
          name: 'payment-methods-go-chyp',
          component: () => import('@pages/app/PaymentMethods/GoChyp.vue'),
          meta: {
            middleware: superAdmin
          },
        },
      ]
    },
    {
      path: '/add-credits',
      name: 'add-credits',
      redirect: {name: 'add-credit-zelle'},
      component: () => import('@pages/app/AddCredits/Index.vue'),
      meta: {
        middleware: adminOrSuperAdmin
      },
      children: [
        {
          path: 'zelle',
          name: 'add-credit-zelle',
          component: () => import('@pages/app/AddCredits/Zelle.vue'),
          meta: {
            middleware: adminOrSuperAdmin
          },
        },
        {
          path: 'go-chyp',
          name: 'add-credit-go-chyp',
          component: () => import('@pages/app/AddCredits/GoChyp.vue'),
          meta: {
            middleware: adminOrSuperAdmin
          },
        },
      ]
    },
    {
      path: '/transactions',
      name: 'transactions',
      component: () => import('@pages/app/Transactions/Table.vue'),
      meta: {
        middleware: adminOrSuperAdmin
      },
    },
  ]
};
